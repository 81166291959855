<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Analysaattori</div>

    <v-card class="mt-2">
      <v-container fluid>
        <v-row dense>
          <!-- Total cashflow -->
          <v-col cols="12" sm="6" md="6" lg="4" xl="2">
            <basic-card
              title="Kassavirta"
              subtitle="Kuluvan kuun kassavirta ennen veroja"
              icon="cash-multiple"
              :color="calcCashFlow() < 0 ? 'error' : 'success'"
              :amount="`${formatCurrency(calcCashFlow())}`"
              tooltip="Kuluvan kuukauden saatavat eräpäivän mukaan - vesimaksut - hoitovastikkeet - rahoitusvastikkeet"
              :updatedAt="`${formatDateAndTime(stats.invoice.updatedAt)}`"
            ></basic-card>
          </v-col>

          <!-- Total apartment loans left -->
          <v-col cols="12" sm="6" md="6" lg="4" xl="2">
            <basic-card
              title="Yhtiölainat"
              subtitle="Yhtiölainaosuudet yhteensä"
              icon="home-minus"
              :amount="`${formatCurrency(stats.apartment.housingCompanyLoans, 0, 0)}`"
              color="warning"
              :updatedAt="`${formatDateAndTime(stats.apartment.updatedAt)}`"
            ></basic-card>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        item-key="_id"
        :headers="headers"
        :items="analyseItems"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <!-- HEADERS -->
        <template #[`header.cashflow`]="{ header }">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <th v-bind="attrs" v-on="on">
                {{ header.text }}
                <v-icon small color="primary" v-on="on">mdi-help-circle</v-icon>
              </th>
            </template>
            <span
              >Kassavirta lasketaan toistuvien laskujen pohjalta: laskun / laskujen loppusumma -
              vesimaksu - hoitovastike - rahoitusvastikkeet</span
            >
          </v-tooltip>
        </template>

        <template #[`header.roi`]="{ header }">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <th v-bind="attrs" v-on="on">
                {{ header.text }}
                <v-icon small color="primary" v-on="on">mdi-help-circle</v-icon>
              </th>
            </template>
            <span
              >Pääoman tuotto lasketaan toistuvien laskujen pohjalta: (laskun / laskujen
              vuokratuotteiden loppusumma - hoitovastike) * 12 / ostohinta * 100</span
            >
          </v-tooltip>
        </template>

        <template #[`header.priceComparison`]="{ header }">
          <v-tooltip bottom max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <th v-bind="attrs" v-on="on">
                {{ header.text }}
                <v-icon small color="primary" v-on="on">mdi-help-circle</v-icon>
              </th>
            </template>
            <span>Hinnan muutos prosentteina (ostohinta vs markkinahinta)</span>
          </v-tooltip>
        </template>

        <!-- Apartment address / Overview link -->
        <template #[`item.address`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartmentId}`"
          >
            <div class="font-weight-bold">
              <span
                >{{ item.address
                }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span>
              </span>
            </div>
          </router-link>
          <div>{{ item.zipCode }}, {{ item.city }}</div>
        </template>

        <!-- Loans -->
        <template #[`item.totalLoans`]="{ item }">
          <div>
            <span>{{ formatCurrency(item.totalLoans) }}</span>
          </div>
        </template>

        <!-- Rent per square -->
        <template #[`item.rentPerSquareState`]="{ item }">
          <div>
            <div v-if="item.rentPerSquareGoal">
              <span>Tavoite: {{ item.rentPerSquareGoal }} €/m²</span>
            </div>

            <div v-if="item.rentPerSquareState && item.rentPerSquareGoal">
              <span>Toteutunut: {{ item.rentPerSquareState }} €/m²</span>
              <v-avatar size="8" class="ball ml-1">
                <v-icon
                  :color="item.rentPerSquareState >= item.rentPerSquareGoal ? 'success' : 'warning'"
                  >mdi-circle</v-icon
                >
              </v-avatar>
            </div>
          </div>
        </template>

        <!-- Price comparison -->
        <template #[`item.priceComparison`]="{ item }">
          <div v-if="item.priceComparison">
            <span :class="item.priceComparison >= 0 ? 'success--text' : 'error--text'"
              >{{ item.priceComparison }} %</span
            >
          </div>
        </template>

        <!-- Cash flow -->
        <template #[`item.cashflow`]="{ item }">
          <span
            class="font-weight-bold"
            :class="item.cashflow > 0 ? 'success--text' : item.cashflow < 0 ? 'error--text' : ''"
            >{{ formatCurrency(item.cashflow) }}</span
          >
        </template>

        <!-- Roi -->
        <template #[`item.roi`]="{ item }">
          <span
            v-if="item.roi || item.roi == 0"
            class="font-weight-bold"
            :class="item.roi > 0 ? 'success--text' : 'error--text'"
          >
            {{ item.roi }} %
          </span>
          <span v-else>Ei laskettavissa</span>
        </template>

        <!-- Actions
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('apartment', 'create')"
              title="Luo kopio"
              small
              @click="createDuplicate(item)"
              >mdi-content-copy</v-icon
            >
          </div>
        </template> -->
        <template #no-data>
          <h2>Ei vuokrakohteita</h2>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import BasicCard from "../../components/Statistics/BasicCard";
import globalValues from "../../configs/globalValues";

export default {
  title: "Analysaattori",

  components: { BasicCard },

  mixins: [mixins],

  data: () => ({
    editMultipleDialog: false,
    attachApartmentDialog: false,
    selected: [],
    searchByDates: false,
    showEndDate: false,
    loading: true,
    options: {},
    dialog: false,
    search: "",
    headers: [
      {
        text: "Osoite",
        value: "address",
      },
      {
        text: "Hinnanmuutos",
        value: "priceComparison",
        sortable: false,
      },
      {
        text: "Yhtiölainat",
        value: "totalLoans",
        sortable: false,
      },
      {
        text: "Neliövuokra",
        value: "rentPerSquareState",
        sortable: false,
      },
      {
        text: "Kassavirta",
        value: "cashflow",
        sortable: false,
      },
      {
        text: "Pääoman tuotto",
        value: "roi",
        sortable: false,
      },
      //   {
      //     text: "Toiminnot",
      //     value: "actions",
      //     sortable: false,
      //   },
    ],
    menuStart: false,
    menuEnd: false,
    dateFormattedStart: "",
    dateFormattedEnd: "",
    dates: {
      startDate: null,
      endDate: null,
    },
    required: [(v) => !!v || "Pakollinen kenttä"],
    globalValues,
  }),

  async created() {
    try {
      await this.getStats();
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  computed: {
    ...mapState("report", ["analyseItems", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    ...mapState("statistics", ["stats"]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("report", ["getApartmentAnalysis"]),
    ...mapActions("statistics", ["getStats"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/reports/analyse-apartments?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;
      await this.getApartmentAnalysis({ url });

      this.loading = false;
    },

    calcCashFlow() {
      const totalIncomes = this.stats.invoice.cashFlowThisMonth;
      const maintenanceTotal = this.stats.apartment.maintenanceChargesThisMonth;
      const waterTotal = this.stats.apartment.waterChargesThisMonth;
      const apartmentLoanPayments = this.stats.apartment.loanPaymentThisMonth;

      return totalIncomes - maintenanceTotal - waterTotal - apartmentLoanPayments;
    },
  },
};
</script>

<style scoped></style>
